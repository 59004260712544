import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { from, Observable } from 'rxjs';
import { StorageService } from '../service/common/storage.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.storageService.get('token'))
      .pipe(
        switchMap(token => {
          if (token) {
            const headers = req.headers
              .set('Authorization', 'Bearer ' + token)
              .append('Content-Type', 'application/json');
            const requestClone = req.clone({ headers });
            return next.handle(requestClone);
          } else {
            return next.handle(req);
          }
        })
      );
  }
}
