import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { STORAGE_KEY } from './const';

@Injectable({
  providedIn: 'root'
})
export class CheckWelcome implements CanLoad {
  constructor(private apiStorage: Storage, private router: Router) {}

  async canLoad() {
    const storage = await this.apiStorage.create();
    return storage.get(`${STORAGE_KEY}_token`).then(res => {
      if (res) {
        this.router.navigate(['/app', 'tabs']);
        return false;
      } else {
        return true;
      }
    });
  }
}
