/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Brand, Car, Logo, Registrations, Respond } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { VehicleService } from 'src/app/shared/service/api/vehicle.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-list-process',
  templateUrl: './list-process.component.html',
  styleUrls: ['./list-process.component.scss']
})
export class ListProcessComponent implements OnInit {
  logos: Logo[] = [];
  brands: Brand[] = [];
  process: Registrations[] = [
    {
      id: '2',
      startTime: new Date(),
      endTime: new Date(),
      result: 0,
      remark: '',
      GCNNumber: '',
      expirationRegistrationDate: new Date(),
      status: 1,
      vehicleId: '12'
    },
    {
      id: '23',
      startTime: new Date(),
      endTime: new Date(),
      result: 0,
      remark: '',
      GCNNumber: '',
      expirationRegistrationDate: new Date(),
      status: 1,
      vehicleId: '11'
    }
  ];
  cars: Car[] = [
    {
      id: '11',
      customerId: '',
      licensePlate: '30L-7878',
      brandId: '25867575-fb02-43b4-54fa-08d9dd855f57',
      lastRegistrationDate: '22/12/2222',
      expirationRegistrationDate: '22/12/2222'
    },
    {
      id: '12',
      customerId: '',
      licensePlate: '30L-7878',
      brandId: '955ba4d4-ff6b-4a7b-54fb-08d9dd855f57',
      lastRegistrationDate: '22/12/2222',
      expirationRegistrationDate: '22/12/2222'
    }
  ];
  constructor(
    private loadingService: LoadingService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private activateRoute: ActivatedRoute,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.storageService.get('brands').then(
          brands => {
            if (brands) {
              this.brands = brands as Brand[];
              for (const brand of this.brands) {
                this.userService.getAvatar(brand.logoUrl).subscribe(
                  data => this.createImageFromBlob(brand.id, data),
                  error => console.error(error)
                );
              }
            }
            this.loadingService.hideLoading();
          },
          error => {
            console.error(error);
            this.loadingService.hideLoading();
          }
        );
      },
      error => {
        console.error(error);
        this.loadingService.hideLoading();
      }
    );
    this.activateRoute.paramMap.subscribe(() => this.getAllVehicle());
  }

  getAllVehicle() {
    this.vehicleService.getAllVehicle().subscribe(
      (res: Respond) => {},
      // (res: Respond) => this.cars = res.result,
      error => console.error(error)
    );
  }

  getCar(vehicleId: string): Car {
    return this.cars.find(car => car.id === vehicleId);
  }

  getLicensePlate(vehicleId: string): string {
    return this.getCar(vehicleId).licensePlate;
  }

  getLogo(vehicleId: string): string | ArrayBuffer {
    const car = this.getCar(vehicleId);
    return this.logos.length > 0 ? this.logos.find(logo => logo.brandId === car.brandId)?.img : '';
  }

  getBrandName(vehicleId: string): string {
    const car = this.getCar(vehicleId);
    return this.brands ? this.brands.find(brand => brand.id === car.brandId)?.name : '';
  }

  createImageFromBlob(brandId: string, image: Blob) {
    const reader = new FileReader(); //you need file reader for read blob data to base64 image data.
    reader.addEventListener('load', () => this.logos.push({ brandId, img: reader.result }), false);// here is the result you got from reader
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
