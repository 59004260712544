export const apiUrl = 'https://dangkiem.taydocantho.com/api/mobile';
export const provinceUrl = 'https://provinces.open-api.vn/api';
export const STORAGE_KEY = '65_05D';

export const CANCEL_TEXT = 'cancel';
export const OK_TEXT = 'ok';
export const STORAGE_KEY_SELECTED_TIME = 'selectedTime';
export const STORAGE_KEY_SELECTED_DATE = 'selectedDate';
export const STORAGE_KEY_SELECTED_CAR = 'selectedCar';
export const STORAGE_KEY_SELECTED_ADDRESS = 'selectedAddress';
