import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OK_TEXT } from 'src/app/shared/common/const';
import { CustomerAddress, Respond } from 'src/app/shared/model/model';
import { BookingService } from 'src/app/shared/service/api/booking.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit {
  addMode = true;
  customerAddress: CustomerAddress = {
    name: '',
    mobile: '',
    addressDistrict: '',
    addressStreet: '',
    addressUnitNo: '',
    remark: '',
    isSelected: false,
    customerId: '',
    inProfile: false
  };
  constructor(
    private modalService: ModalService,
    private bookingService: BookingService,
    private router: Router,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    const urlData = this.router.getCurrentNavigation().extras.state;
    if (urlData) {
      this.customerAddress = urlData.customerAddress;
      this.addMode = false;
    }
  }

  save() {
    this.loadingService.showLoading().then(
      () => {
        this.bookingService.saveAddress(this.customerAddress).subscribe(
          (respond: Respond) => {
            if (respond.isError) {
              this.modalService.showError(respond);
            } else {
              this.loadingService.hideLoading();
              this.router.navigateByUrl('/booking/method/address');
            }
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }

  async deleteAddress() {
    this.modalService.confirmNegative('Bạn muốn xóa địa chỉ?')
      .then(
        data => {
          if (data === OK_TEXT) {
            this.loadingService.showLoading().then(
              () => {
                this.bookingService.deleteAddress(this.customerAddress.id).subscribe(
                  (respond: Respond) => {
                    if (respond.isError) {
                      this.modalService.showError(respond);
                    } else {
                      this.loadingService.hideLoading();
                      this.router.navigateByUrl('/booking/method/address');
                    }
                  },
                  error => this.modalService.showError(error)
                );
              },
              error => this.modalService.showError(error)
            );
          }
        },
        error => console.error(error)
      );
  }
}
