import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CANCEL_TEXT, OK_TEXT } from 'src/app/shared/common/const';
import { Respond, User } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';
import { AddressComponent } from '../../user/signup/address/address.component';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  selector: 'app-edit-personal',
  templateUrl: './edit-personal.component.html',
  styleUrls: ['./edit-personal.component.scss']
})
export class EditPersonalComponent implements OnInit {
  maxDate = new Date().toISOString().substring(0, 10);
  avatar: string | ArrayBuffer;
  nameRequired = false;
  user: User = {
    fullName: '',
    userName: '',
    gender: '',
    birthDate: null,
    identificationNumber: '',
    addressDistrict: '',
    addressStreet: '',
    addressUnitNo: '',
    id: '',
    mobile: '',
    role: 1,
    isActive: true
  };
  constructor(
    private router: Router,
    private userService: UserService,
    private loadingService: LoadingService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.userService.getUserInfo().subscribe(
          (respond: Respond) => {
            this.user = respond.result as User;
            if (this.user.profilePicUrl) {
              this.userService.getAvatar(this.user.profilePicUrl).subscribe(
                data => this.createImageFromBlob(data),
                error => console.error(error)
              );
            }
            this.loadingService.hideLoading();
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader(); //you need file reader for read blob data to base64 image data.
    reader.addEventListener('load', () => this.avatar = reader.result, false); // here is the result you got from reader
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  closePopup() {
    this.modalService.dismiss();
  }

  changeAvatar() {
    this.modalService.openSheetModal(AvatarComponent, 0.25, [0.25]).then(
      result => {
        if (result && result.toString() === OK_TEXT) {
          console.log(result);
        }
      },
      error => console.error(error)
    );
  }

  openAddress() {
    this.modalService.openSheetModal(AddressComponent, 0.5, [0.5]).then(
      result => {
        if (result && result.toString() !== CANCEL_TEXT) {
          this.user.addressDistrict = `${result.province.name}/${result.district.name}/${result.ward.name}`;
        }
      },
      error => console.error(error)
    );
  }

  save() {
    if (this.user.fullName) {
      this.loadingService.showLoading().then(
        () => {
          this.userService.register(this.user).subscribe(
            (respond: Respond) => {
              if (respond.isError) {
                const buttons = [{ title: 'OK', link: '' }];
                this.modalService.notify(false, 'Lỗi!!', 'Đăng nhập thất bại', buttons);
              } else {
                this.router.navigateByUrl('/tabs/personal');
              }
              this.loadingService.hideLoading();
            },
            error => this.modalService.showError(error)
          );
        }
      );
    } else {
      this.nameRequired = true;
    }
  }
}
