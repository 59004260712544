import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInput } from '@ionic/angular';
import { Respond } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('otp1') otp1: IonInput;
  @ViewChild('otp2') otp2: IonInput;
  @ViewChild('otp3') otp3: IonInput;
  @ViewChild('otp4') otp4: IonInput;
  @ViewChild('otp5') otp5: IonInput;
  @ViewChild('otp6') otp6: IonInput;
  currentStep = 1;
  wrongMobile = false;
  showPass = false;
  repass = '';
  password = '';
  passNotMatch = false;
  passTooShort = false;
  passRequired = false;
  nameRequired = false;
  emailRequired = false;
  mobile = '';
  otp = [null, null, null, null, null, null];
  wrongOTP = false;
  requiredOTP = false;
  constructor(
    private router: Router,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  checkMatchPassword() {
    if (this.repass !== this.password) {
      this.passNotMatch = true;
    }
  }

  sendOTP(resend = false) {
    this.loadingService.showLoading().then(
      data => {
        this.userService.forgotPassword(this.mobile.toString()).subscribe(
          (res: Respond) => {
            if (res.isError) {
              this.modalService.showError(res);
            } else {
              if (!resend) {
                this.currentStep++;
              }
              this.loadingService.hideLoading();
            }
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }

  back() {
    if (this.currentStep === 1) {
      this.router.navigateByUrl('/login');
    } else {
      this.currentStep--;
    }
  }

  verifyOTP() {
    const otpStr = this.otp.join('');
    if (otpStr.length === 6) {
      this.loadingService.showLoading().then(
        data => {
          this.userService.resetPassword(this.mobile.toString(), otpStr).subscribe(
            (respond: Respond) => {
              if (respond.isError) {
                this.wrongOTP = true;
                this.modalService.showError('Sai mã OTP');
              } else {
                this.currentStep++;
                this.loadingService.hideLoading();
              }
            },
            error => this.modalService.showError(error)
          );
        },
        error => this.modalService.showError(error)
      );
    } else {
      this.requiredOTP = true;
    }
  }

  changePassword() {
    this.loadingService.showLoading().then(
      data => {
        this.userService.changePassword(this.mobile, this.password, null, this.otp.join('')).subscribe(
          (respond: Respond) => {
            if (respond.isError) {
              this.modalService.showError(respond);
            } else {
              this.loadingService.hideLoading();
              this.modalService.notify(
                true,
                'Đổi mật khẩu thành công',
                'Chào mừng bạn đến với trung tâm Đăng kiểm xe cơ giới 65-05d',
                [{ title: 'Đăng nhập', link: '/login' }],
              );
            }
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }

  inputOTP(event, pre?: IonInput, current?: IonInput, next?: IonInput) {
    this.wrongOTP = false;
    if (current.value.toString().length > 0 && +current.value >= 0 && +current.value <= 9) {
      if (next) {
        next.setFocus();
      }
    } else if (event.keyCode === 8 || event.keyCode === 46) { // delete
      if (pre) {
        pre.setFocus();
      }
    } else {
      current.setFocus();
    }
  }
}
