import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BookingCarComponent } from './page/booking/car/car.component';
import { BookingDateComponent } from './page/booking/date/date.component';
import { BookingMethodComponent } from './page/booking/method/method.component';
import { CheckWelcome } from './shared/common/check-welcome.service';
import { SigninComponent } from './page/user/signin/signin.component';
import { BookingAddressComponent } from './page/booking/method/address/address.component';
import { EditAddressComponent } from './page/booking/method/edit-address/edit-address.component';
import { ForgotPasswordComponent } from './page/user/forgot-password/forgot-password.component';
import { EditPersonalComponent } from './page/personal/edit-personal/edit-personal.component';
import { SignupComponent } from './page/user/signup/signup.component';
import { ChangePasswordComponent } from './page/user/change-password/change-password.component';
import { VehicleEditComponent } from './page/vehicle/edit/edit.component';
import { StaffInChargeComponent } from './page/booking/staff-in-charge/staff-in-charge.component';
import { ListProcessComponent } from './page/process/list-process/list-process.component';
import { DetailProcessComponent } from './page/process/detail-process/detail-process.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    loadChildren: () => import('./page/welcome/welcome.module').then(m => m.WelcomePageModule),
    canLoad: [CheckWelcome]
  },
  {
    path: 'login',
    component: SigninComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'edit-personal',
    component: EditPersonalComponent
  },
  {
    path: 'tabs',
    loadChildren: () => import('./page/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'vehicle/:id',
    component: VehicleEditComponent
  },
  {
    path: 'booking/car',
    component: BookingCarComponent
  },
  {
    path: 'booking/date',
    component: BookingDateComponent
  },
  {
    path: 'booking/method',
    component: BookingMethodComponent
  },
  {
    path: 'booking/method/address',
    component: BookingAddressComponent
  },
  {
    path: 'booking/method/address/edit',
    component: EditAddressComponent
  },
  {
    path: 'process/list',
    component: ListProcessComponent
  },
  {
    path: 'process/detail/:id',
    component: DetailProcessComponent
  },
  {
    path: 'staff-in-charge/:id',
    component: StaffInChargeComponent
  },
  {
    path: '**',
    redirectTo: '/tabs/home'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
