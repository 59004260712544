import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Car, Logo, Respond } from 'src/app/shared/model/model';
import { StorageService } from 'src/app/shared/service/common/storage.service';
import { STORAGE_KEY_SELECTED_CAR } from 'src/app/shared/common/const';
import { VehicleService } from 'src/app/shared/service/api/vehicle.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { UserService } from 'src/app/shared/service/api/user.service';

@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.scss']
})
export class BookingCarComponent implements OnInit {
  selectedCar = 0;
  logos: Logo[] = [];
  cars: Car[] = [
    {
      id: '1',
      customerId: 'f2dd8ddf-2848-426a-40f7-08d9daeb083d',
      lastRegistrationDate: '2022-01-29',
      expirationRegistrationDate: '2022-01-31',
      licensePlate: '88-545',
      brandId: '955ba4d4-ff6b-4a7b-54fb-08d9dd855f57'
    },
    {
      id: '2',
      customerId: 'f2dd8ddf-2848-426a-40f7-08d9daeb083d',
      lastRegistrationDate: '2022-01-29',
      expirationRegistrationDate: '2022-01-31',
      licensePlate: '88-545',
      brandId: '955ba4d4-ff6b-4a7b-54fb-08d9dd855f57'
    }
  ];
  constructor(
    private router: Router,
    private storageService: StorageService,
    private loadingService: LoadingService,
    private vehicleService: VehicleService,
    private userService: UserService
  ) { }

  ngOnInit() {
    // this.vehicleService.getAllVehicle().subscribe(
    //   (res: Respond) => this.cars = res.result,
    //   error => console.error(error)
    // );
    this.loadingService.showLoading().then(
      () => {
        this.storageService.get('brands').then(
          brands => {
            if (brands) {
              for (const brand of brands) {
                this.userService.getAvatar(brand.logoUrl).subscribe(
                  data => this.createImageFromBlob(brand.id, data),
                  error => console.error(error)
                );
              }
            }
            this.loadingService.hideLoading();
          },
          error => {
            console.error(error);
            this.loadingService.hideLoading();
          }
        );
      },
      error => {
        console.error(error);
        this.loadingService.hideLoading();
      }
    );
  }

  createImageFromBlob(brandId: string, image: Blob) {
    const reader = new FileReader(); //you need file reader for read blob data to base64 image data.
    reader.addEventListener('load', () => this.logos.push({ brandId, img: reader.result }), false);// here is the result you got from reader
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  submit() {
    this.storageService.set(STORAGE_KEY_SELECTED_CAR, this.cars[this.selectedCar].id);
    this.router.navigateByUrl('/booking/date');
  }
}
