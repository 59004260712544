import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BookingCarComponent } from './page/booking/car/car.component';
import { BookingDateComponent } from './page/booking/date/date.component';
import { BookingAddressComponent } from './page/booking/method/address/address.component';
import { EditAddressComponent } from './page/booking/method/edit-address/edit-address.component';
import { BookingMethodComponent } from './page/booking/method/method.component';
import { BookingSuccessComponent } from './page/booking/success/success.component';
import { SigninComponent } from './page/user/signin/signin.component';
import { VehicleDetailComponent } from './page/vehicle/detail/detail.component';
import { ListVehicleComponent } from './page/vehicle/list-vehicle/list-vehicle.component';
import { VehicleTimelineComponent } from './page/vehicle/timeline/timeline.component';
import { VehicleEditComponent } from './page/vehicle/edit/edit.component';
import { ConfirmNegativeComponent } from './page/common/popup/confirm-negative/confirm-negative.component';
import { ConfirmPositiveComponent } from './page/common/popup/confirm-positive/confirm-positive.component';
import { NotifyComponent } from './page/common/popup/notify/notify.component';
import { ForgotPasswordComponent } from './page/user/forgot-password/forgot-password.component';
import { AvatarComponent } from './page/personal/avatar/avatar.component';
import { SignupComponent } from './page/user/signup/signup.component';
import { AddressComponent } from './page/user/signup/address/address.component';
import { AuthInterceptor } from './shared/interceptor/auth-interceptor';
import { ChangePasswordComponent } from './page/user/change-password/change-password.component';
import { VehicleCancelHistoryComponent } from './page/vehicle/cancel-history/cancel-history.component';
import { StaffInChargeComponent } from './page/booking/staff-in-charge/staff-in-charge.component';
import { NotificationComponent } from './page/notification/notification.component';
import { ListProcessComponent } from './page/process/list-process/list-process.component';
import { DetailProcessComponent } from './page/process/detail-process/detail-process.component';
import { StorageService } from './shared/service/common/storage.service';
import { LoadingService } from './shared/service/common/loading.service';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignupComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    BookingCarComponent,
    BookingDateComponent,
    BookingMethodComponent,
    BookingAddressComponent,
    EditAddressComponent,
    BookingSuccessComponent,
    ListProcessComponent,
    DetailProcessComponent,
    ListVehicleComponent,
    VehicleDetailComponent,
    VehicleTimelineComponent,
    VehicleEditComponent,
    VehicleCancelHistoryComponent,
    ConfirmNegativeComponent,
    ConfirmPositiveComponent,
    NotifyComponent,
    AvatarComponent,
    AddressComponent,
    StaffInChargeComponent,
    NotificationComponent
],
  entryComponents: [],
  imports: [
    BrowserModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule
  ],
  exports: [],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ConfirmNegativeComponent,
    ConfirmPositiveComponent,
    NotifyComponent,
    AvatarComponent,
    AddressComponent,
    StorageService,
    LoadingService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
