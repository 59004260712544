import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class BookingSuccessComponent implements OnInit {

  constructor(private modal: ModalController) { }

  ngOnInit() {
  }

  goHome() {
    this.modal.dismiss();
  }

  closePopup() {
    this.modal.dismiss();
  }
}
