import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Brand, Car, Logo, Respond } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { VehicleService } from 'src/app/shared/service/api/vehicle.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class VehicleTimelineComponent implements OnInit {
  logos: Logo[] = [];
  car: Car = {
    id: '',
    customerId: '',
    licensePlate: '30L-7878',
    brandId: '25867575-fb02-43b4-54fa-08d9dd855f57',
    lastRegistrationDate: '22/12/2222',
    expirationRegistrationDate: '22/12/2222'
  };
  brands: Brand[];
  timeline = [
    {
      title: 'Đăng kiểm thành công',
      date: '22/12/2021',
      note: 'Xe đủ điều kiện lưu thông',
      status: 'Hoàn thành'
    },
    {
      title: 'Đăng kiểm thất bại',
      date: '21/12/2021',
      note: 'Khiếm khuyết, hư hỏng quan trọng (MAJOR DEFECTS - MaD)',
      status: 'Thất bại'
    },
    {
      title: 'Đang đăng kiểm',
      date: '21/12/2021',
      note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      status: 'Đang đăng kiểm'
    }
  ];
  constructor(
    private loadingService: LoadingService,
    private modalService: ModalService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private storageService: StorageService,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.storageService.get('brands').then(
      brands => {
        if (brands) {
          this.brands = brands as Brand[];
          for (const brand of this.brands) {
            this.userService.getAvatar(brand.logoUrl).subscribe(
              data => this.createImageFromBlob(brand.id, data),
              error => console.error(error)
            );
          }
        }
      },
      error => console.error(error)
    );
    this.loadingService.showLoading().then(
      () => {
        this.activateRoute.paramMap.subscribe(
          (paramMap: ParamMap) => {
            this.vehicleService.getVehicle(paramMap.get('id')).subscribe(
              (res: Respond) => {
                if (res.isError) {
                  this.modalService.showError(res);
                } else {
                  this.loadingService.hideLoading();
                  this.car = res.result;
                }
              },
              error => this.modalService.showError(error)
            );
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }

  createImageFromBlob(brandId: string, image: Blob) {
    const reader = new FileReader(); //you need file reader for read blob data to base64 image data.
    reader.addEventListener('load', () => this.logos.push({ brandId, img: reader.result }), false);// here is the result you got from reader
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getLogo(brandId: string): string | ArrayBuffer {
    return this.logos.length > 0 ? this.logos.find(logo => logo.brandId === brandId)?.img : '';
  }

  getBrandName(brandId: string): string {
    return this.brands ? this.brands.find(brand => brand.id === brandId).name : '';
  }
}
