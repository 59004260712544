import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Respond } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { VehicleService } from 'src/app/shared/service/api/vehicle.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  mobile = '';
  password = '';
  showPass = false;
  constructor(
    private modalService: ModalService,
    private loadingService: LoadingService,
    private userService: UserService,
    private storageService: StorageService,
    private vehicleService: VehicleService,
    private router: Router) { }

  ngOnInit() {
  }

  signIn() {
    this.loadingService
      .showLoading()
      .then(
        () => {
          this.userService
            .signIn(this.mobile, this.password)
            .subscribe(
              (respond: Respond) => {
                if (respond.isError) {
                  this.modalService.showError('Đăng nhập thất bại');
                } else {
                  this.storageService.set('mobile', this.mobile);
                  this.storageService.set('token', respond.result)
                    .then(
                      () => {
                        this.vehicleService.getAllBrand().subscribe(
                          (res: Respond) => {
                            if (!res.isError) {
                              this.storageService.set('brands', res.result);
                            }
                          },
                          error => console.error(error)
                        );
                      },
                      error => console.error(error)
                    );
                  this.router.navigateByUrl('/tabs/home');
                }
                this.loadingService.hideLoading();
              },
              error => this.loadingService.hideLoading()
            );
        }
      );
  }
}
