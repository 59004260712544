import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl, provinceUrl } from '../../common/const';
import { District, Province, Respond, User } from '../../model/model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  signIn(mobile: string, password: string): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/sign-in`, { mobile, password });
  }

  createOTP(mobile: string): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/request-otp`, { mobile });
  }

  verifyOTP(code: string, mobile: string): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/verify-otp`, { code, mobile });
  }

  register(user: User): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/profile`, user);
  }

  forgotPassword(mobile: string): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/forgot-password`, { mobile });
  }

  changePassword(mobile: string, newPassword: string, oldPassword?: string, code?: string): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/change-password`, { mobile, newPassword, oldPassword, code });
  }

  resetPassword(mobile: string, code: string) {
    return this.http.post<Respond>(`${apiUrl}/reset-password`, { mobile, code });
  }

  getUserInfo(): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/profile/detail`, {});
  }

  uploadAvatar(file: File): Observable<Respond> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<Respond>(`${apiUrl}/upload-avatar`, formData);
  }

  getAvatar(url: string): Observable<Blob> {
    return this.http.get(`${apiUrl}/download?url=${url}`, { responseType: 'blob' });
  }

  getProvinces(): Observable<Province[]> {
    return this.http.get<Province[]>(`${provinceUrl}/?depth=2`);
  }

  getWards(districtCode: number): Observable<District> {
    return this.http.get<District>(`${provinceUrl}/d/${districtCode}?depth=2`);
  }
}
