import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from '../../common/const';
import { Car } from '../../model/model';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  path = 'vehicle';
  constructor(private http: HttpClient) { }

  getAllVehicle() {
    return this.http.post(`${apiUrl}/${this.path}/get-all`, {});
  }

  delete(id: string) {
    return this.http.post(`${apiUrl}/${this.path}/delete`, { id });
  }

  getAllBrand() {
    return this.http.post(`${apiUrl}/brand/get-all`, {});
  }

  getVehicle(id: string) {
    return this.http.post(`${apiUrl}/${this.path}/detail`, { id });
  }

  addVehicle(car: Car) {
    return this.http.post(`${apiUrl}/${this.path}`, { car });
  }
}
