import { ModalService } from 'src/app/shared/service/common/modal.service';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { CANCEL_TEXT } from 'src/app/shared/common/const';
import { UserService } from 'src/app/shared/service/api/user.service';
import { Respond } from 'src/app/shared/model/model';
import { LoadingService } from 'src/app/shared/service/common/loading.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  photo = CameraSource.Photos;
  camera = CameraSource.Camera;
  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private modalService: ModalService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
  }

  async uploadImage(source: any) {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source
    });
    const blob = new Blob([image.base64String], { type: `image/${image.format}` });
    const file = new File([blob], `avatar.${image.format}`);

    this.loadingService.showLoading().then(
      () => {
        this.userService.uploadAvatar(file).subscribe(
          (res: Respond) => {
            this.modalController.dismiss(image);
            this.loadingService.hideLoading();
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }

  close() {
    this.modalController.dismiss(CANCEL_TEXT);
  }
}
