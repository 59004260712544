/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { STORAGE_KEY } from 'src/app/shared/common/const';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storage: Storage | null = null;

  constructor(private apiStorage: Storage) { }

  async init() {
    this.storage = await this.apiStorage.create();
  }

  public set(key: string, value: any) {
    return this.storage?.set(`${STORAGE_KEY}_${key}`, value);
  }

  public async get(key: string) {
    return await this.storage?.get(`${STORAGE_KEY}_${key}`);
  }

  public async remove(key: string) {
    return await this.storage?.remove(`${STORAGE_KEY}_${key}`);
  }

  public async clear() {
    return await this.storage?.clear();
  }
}
