import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonDatetime, ModalController } from '@ionic/angular';
import { STORAGE_KEY_SELECTED_DATE, STORAGE_KEY_SELECTED_TIME } from 'src/app/shared/common/const';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class BookingDateComponent implements OnInit {
  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;
  minDate = new Date().toISOString().substring(0, 10);
  days = ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7', 'CN'];
  selected = new Date();
  selectedByPopup = this.minDate;
  selectedTime = '8:00';
  morning = ['8:00', '9:00', '10:00', '11:00', '12:00'];
  afternoon = ['13:00', '14:00', '15:00', '16:00', '17:00'];
  vehicleId = '';
  week: Date[] = [];
  minOfWeek: number;
  maxOfWeek: number;

  constructor(
    private storageService: StorageService,
    public router: Router,
    private modal: ModalController
  ) { }

  ngOnInit() {
    this.days.forEach((day, i) => { // ['Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7', 'CN'];
      const currentDayOfWeek = this.selected.getDay() - 1;
      this.week.push(this.addTimeToDate(this.selected, i - currentDayOfWeek));
    });
  }

  checkActive(date: Date) {
    return date.getDate() === this.selected.getDate() &&
      date.getMonth() === this.selected.getMonth() &&
      date.getFullYear() === this.selected.getFullYear();
  }

  formatDate(value: string) {
    return new Date(value);
  }

  addTimeToDate(date: Date, distance: number) {
    return new Date(date.getTime() + (3600 * 1000 * 24 * distance));
  }

  calculateDateOfWeek(addOrMinus: number) {
    this.week = this.week.map(day => this.addTimeToDate(day, addOrMinus));
  }

  submit() {
    this.storageService.set(STORAGE_KEY_SELECTED_DATE, this.selected);
    this.storageService.set(STORAGE_KEY_SELECTED_TIME, this.selectedTime);
    this.router.navigateByUrl('/booking/method');
  }

  closePopup() {
    this.modal.dismiss();
  }

  selectTime(time: string) {
    this.selectedTime = time;
  }

  selectDate(date: Date) {
    this.selected = date;
  }

  pre() {
    this.calculateDateOfWeek(-7);
  }

  next() {
    this.calculateDateOfWeek(7);
  }
}
