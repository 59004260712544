/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Brand, Car, Respond, UserInfo } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { VehicleService } from 'src/app/shared/service/api/vehicle.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';
import jwt_decode from 'jwt-decode';
import { StorageService } from 'src/app/shared/service/common/storage.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class VehicleEditComponent implements OnInit {
  brandRequired = false;
  licensePlateRequired = false;
  nameRequired = false;
  addMode = true;
  fromPage = 'list';
  userInfo: UserInfo = {
    Id: '',
    IsFirstLogin: false,
    Mobile: '',
    Name: '',
    Role: '',
    UserName: '',
    exp: ''
  };
  car: Car = {
    customerId: '',
    licensePlate: '',
    brandId: '',
    lastRegistrationDate: undefined,
    expirationRegistrationDate: undefined
  };
  logo: string | ArrayBuffer = '';
  brands: Brand[] = [];
  maxDate = new Date().toISOString().substring(0, 10);
  constructor(
    private vehicleService: VehicleService, private modalService: ModalService,
    private userService: UserService, private activateRoute: ActivatedRoute,
    private storageService: StorageService, private loadingService: LoadingService,
    private router: Router,
  ) { }

  ngOnInit() {
    const urlData = this.router.getCurrentNavigation().extras.state;
    if (urlData) {
      this.fromPage = urlData.fromPage;
    }
    this.storageService.get('token').then(
      data =>  {
        if (data) {
          this.userInfo = jwt_decode(data);
          this.car.customerId = this.userInfo.Id;
        }
      },
      error => console.error(error)
    );
    this.storageService.get('brands').then(
      brands => {
        if (brands) {
          this.brands = brands as Brand[];
        }
      },
      error => console.error(error)
    );
    this.activateRoute.paramMap.subscribe(
      (paramMap: ParamMap) => {
        const id = paramMap.get('id');
        if (id === 'new') {
          this.addMode = true;
        } else {
          this.addMode = false;
          this.vehicleService.getVehicle(id).subscribe(
            (respond: Respond) => console.log(respond),
            error => console.error(error)
          );
        }
      },
      error => console.error(error)
    );
  }

  selectBrand(event) {
    const logo = this.brands.find(brand => brand.id === event).logoUrl;
    this.userService.getAvatar(logo).subscribe(
      data => this.createImageFromBlob(data),
      error => {}
    );
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader(); //you need file reader for read blob data to base64 image data.
    reader.addEventListener('load', () => {
      this.logo = reader.result; // here is the result you got from reader
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  submit() {
    if (!this.car.brandId) {
      this.brandRequired = true;
      return;
    }
    if (!this.car.licensePlate) {
      this.licensePlateRequired = true;
      return;
    }
    if (!this.userInfo.Name) {
      this.nameRequired = true;
      return;
    }
    this.loadingService.showLoading().then(
      () => {
        this.vehicleService.addVehicle(this.car).subscribe(
          (respond: Respond) => {
            if (respond.isError) {
              this.modalService.showError(respond);
            } else {
              const msg = this.addMode ? 'Thêm' : 'Sửa';
              this.modalService.notify(
                true, '', msg + ' thông tin xe thành công', [{ title: 'Quản lý phương tiện', link: '/tabs/vehicle/list' }]
              );
            }
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }

  closePopup() {
    this.modalService.dismiss();
  }
}
