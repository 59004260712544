/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Logo, Brand, Registrations, Car, Staff } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-detail-process',
  templateUrl: './detail-process.component.html',
  styleUrls: ['./detail-process.component.scss']
})
export class DetailProcessComponent implements OnInit {
  currentStep = 0;
  showAll = false;
  logos: Logo[] = [];
  brands: Brand[] = [];
  staff: Staff = {
    id: '12',
    staffNumber: '',
    userId: ''
  };
  selectedProcess: Registrations = {
    id: '',
    startTime: new Date(),
    endTime: new Date(),
    result: 0,
    remark: '',
    GCNNumber: '',
    expirationRegistrationDate: new Date(),
    status: 1,
    vehicleId: '11'
  };
  process: Registrations[] = [
    {
      id: '2',
      startTime: new Date(),
      endTime: new Date(),
      result: 0,
      remark: '',
      GCNNumber: '',
      expirationRegistrationDate: new Date(),
      status: 1,
      vehicleId: '12'
    },
    {
      id: '23',
      startTime: new Date(),
      endTime: new Date(),
      result: 0,
      remark: '',
      GCNNumber: '',
      expirationRegistrationDate: new Date(),
      status: 1,
      vehicleId: '11'
    }
  ];
  cars: Car[] = [
    {
      id: '11',
      customerId: '',
      licensePlate: '30L-7878',
      brandId: '25867575-fb02-43b4-54fa-08d9dd855f57',
      lastRegistrationDate: '22/12/2222',
      expirationRegistrationDate: '22/12/2222'
    },
    {
      id: '12',
      customerId: '',
      licensePlate: '30L-7878',
      brandId: '955ba4d4-ff6b-4a7b-54fb-08d9dd855f57',
      lastRegistrationDate: '22/12/2222',
      expirationRegistrationDate: '22/12/2222'
    }
  ];
  timeline = [
    {
      time: new Date(1643939891539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643939191539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643919191539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643819191539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643119191539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643939891539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643939191539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643919191539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643819191539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643119191539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    }
  ];
  timelineLite = [
    {
      time: new Date(1643939891539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    },
    {
      time: new Date(1643939191539),
      title: 'Trả xe',
      detail: 'Trung tâm đang điều phối nhân đến nhận'
    }
  ];

  constructor(
    private alertController: AlertController,
    private modalService: ModalService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    private activateRoute: ActivatedRoute,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.storageService.get('brands').then(
      brands => {
        if (brands) {
          this.brands = brands as Brand[];
          for (const brand of this.brands) {
            this.userService.getAvatar(brand.logoUrl).subscribe(
              data => this.createImageFromBlob(brand.id, data),
              error => console.error(error)
            );
          }
        }
      },
      error => console.error(error)
    );
    this.activateRoute.paramMap.subscribe(
      (paramMap) => {
        if (paramMap.get('id')) {
          this.selectedProcess = this.process.find(process => process.id === paramMap.get('id'));
        }
      },
      error => console.error(error)
    );
  }

  createImageFromBlob(brandId: string, image: Blob) {
    const reader = new FileReader(); //you need file reader for read blob data to base64 image data.
    reader.addEventListener('load', () => this.logos.push({ brandId, img: reader.result }), false);// here is the result you got from reader
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getCar(vehicleId: string): Car {
    return this.cars.find(car => car.id === vehicleId);
  }

  getLicensePlate(vehicleId: string): string {
    return this.getCar(vehicleId)?.licensePlate;
  }

  getLogo(vehicleId: string): string | ArrayBuffer {
    const car = this.getCar(vehicleId);
    return this.logos.length > 0 ? this.logos.find(logo => logo.brandId === car.brandId)?.img : '';
  }

  getBrandName(vehicleId: string): string {
    const car = this.getCar(vehicleId);
    return this.brands ? this.brands.find(brand => brand.id === car.brandId)?.name : '';
  }

  async cancel() {
    const alert = await this.alertController.create({
      header: 'Lí do hủy đơn hàng',
      inputs: [
        {
          name: 'checkbox1',
          type: 'checkbox',
          label: 'Thời gian lấy xe chậm',
          value: 'value1'
        },
        {
          name: 'checkbox2',
          type: 'checkbox',
          label: 'Muốn thay đổi địa chỉ hoặc thời gian đặt lịch Đăng kiểm',
          value: 'value2'
        },
        {
          name: 'checkbox3',
          type: 'checkbox',
          label: 'Đổi ý không muốn đặt lịch Đăng kiểm',
          value: 'value3'
        },
        {
          name: 'checkbox4',
          type: 'checkbox',
          label: 'Khác',
          value: 'value4',
          checked: true,
          handler: () => {
            console.log('Checkbox 4 selected');
          }
        }
      ],
      buttons: [
        {
          text: 'Hủy',
          handler: (data) => {
            console.log('Confirm Ok', data);
            this.modalService.notify(
              false,
              'Đã hủy đặt lịch',
              'Chúng tôi rất tiếc khi bạn đã hủy lịch đăng kiểm tại trung tâm',
              [{ title: 'Trở về trang chủ', link: '/tabs/home' }]
            );
          }
        }
      ]
    });
    await alert.present();
  }
}
