import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  STORAGE_KEY_SELECTED_ADDRESS,
  STORAGE_KEY_SELECTED_CAR,
  STORAGE_KEY_SELECTED_DATE,
  STORAGE_KEY_SELECTED_TIME
} from 'src/app/shared/common/const';
import { Booking, CustomerAddress, Respond } from 'src/app/shared/model/model';
import { BookingService } from 'src/app/shared/service/api/booking.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-method',
  templateUrl: './method.component.html',
  styleUrls: ['./method.component.scss']
})
export class BookingMethodComponent implements OnInit {
  note = '';
  address: CustomerAddress[] = [];
  customerAddress: CustomerAddress = {
    id: 'ưef',
    name: '',
    mobile: '',
    addressDistrict: '',
    addressStreet: '',
    addressUnitNo: '',
    remark: '',
    isSelected: false,
    customerId: '',
    inProfile: false
  };
  booking: Booking = {
    vehicleId: '',
    customerAddressId: this.customerAddress.id,
    bookingDate: undefined,
    bookingTimeFrame: '',
    shippingMethod: 1,
    remark: '',
    status: 0
  };
  constructor(
    public modalService: ModalService,
    public router: Router,
    private storageService: StorageService,
    private bookingService: BookingService,
    private activateRoute: ActivatedRoute,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe(() => this.getDataFromStorage());
    this.loadingService.showLoading().then(
      () => {
        this.bookingService.getAllAddress().subscribe(
          (respond: Respond) => {
            if (respond.isError) {
              this.modalService.showError(respond);
            } else if (respond.result.length > 0) {
              this.address = respond.result;
              this.customerAddress = respond.result.find((ca: CustomerAddress) => ca.isSelected);
              this.loadingService.hideLoading();
            }
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }

  getDataFromStorage() {
    this.storageService.get(STORAGE_KEY_SELECTED_TIME).then(
      data => {
        if (data) {
          this.booking.bookingTimeFrame = data;
        }
      },
      error => console.error(error)
    );
    this.storageService.get(STORAGE_KEY_SELECTED_DATE).then(
      data => {
        if (data) {
          this.booking.bookingDate = data;
        }
      },
      error => console.error(error)
    );
    this.storageService.get(STORAGE_KEY_SELECTED_ADDRESS).then(
      data => {
        if (data) {
          this.booking.customerAddressId = data;
          if (this.address.length > 0) {
            this.customerAddress = this.address.find((ca: CustomerAddress) => ca.id === data);
          }
        }
      },
      error => console.error(error)
    );
    this.storageService.get(STORAGE_KEY_SELECTED_CAR).then(
      data => {
        if (data) {
          this.booking.vehicleId = data;
        }
      },
      error => console.error(error)
    );
  }

  async submitBooking() {
    console.log(this.booking);
    // this.loadingService.showLoading().then(
    //   () => {
    //     this.bookingService.booking(this.booking).subscribe(
    //       (respond: Respond) => {
    //         if (respond.isError) {
    //           this.modalService.showError(respond);
    //         } else {
    //           const buttons = [
    //             { title: 'Trở về trang chủ', link: '/' },
    //             { title: 'Theo dõi tiến trình', link: '/' }
    //           ];
    //           this.modalService.notify(
    //             true,
    //             'Đặt lịch thành công',
    //             'Chào mừng bạn đến với Trung tâm Đăng kiểm xe cơ giới 65-05d',
    //             buttons
    //           );
    //         }
    //         this.loadingService.hideLoading();
    //       },
    //       error => this.modalService.showError(error)
    //     );
    //   },
    //   error => this.modalService.showError(error)
    // );
  }
}
