import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Brand, Car, Logo, Respond } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { VehicleService } from 'src/app/shared/service/api/vehicle.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-list-vehicle',
  templateUrl: './list-vehicle.component.html',
  styleUrls: ['./list-vehicle.component.scss']
})
export class ListVehicleComponent implements OnInit {
  fromPage = 'home';
  logos: Logo[] = [];
  cars: Car[] = [
    {
      id: '11',
      customerId: '',
      licensePlate: '30L-7878',
      brandId: '25867575-fb02-43b4-54fa-08d9dd855f57',
      lastRegistrationDate: '22/12/2222',
      expirationRegistrationDate: '22/12/2222'
    },
    {
      id: '12',
      customerId: '',
      licensePlate: '30L-7878',
      brandId: '955ba4d4-ff6b-4a7b-54fb-08d9dd855f57',
      lastRegistrationDate: '22/12/2222',
      expirationRegistrationDate: '22/12/2222'
    }
  ];
  brands: Brand[];
  constructor(
    private vehicleService: VehicleService,
    private router: Router,
    private loadingService: LoadingService,
    private userService: UserService,
    private activateRoute: ActivatedRoute,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.loadingService.showLoading().then(
      () => {
        this.storageService.get('brands').then(
          brands => {
            if (brands) {
              this.brands = brands as Brand[];
              for (const brand of this.brands) {
                this.userService.getAvatar(brand.logoUrl).subscribe(
                  data => this.createImageFromBlob(brand.id, data),
                  error => console.error(error)
                );
              }
            }
            this.loadingService.hideLoading();
          },
          error => {
            console.error(error);
            this.loadingService.hideLoading();
          }
        );
      },
      error => {
        console.error(error);
        this.loadingService.hideLoading();
      }
    );
    this.activateRoute.paramMap.subscribe(
      (paramMap: ParamMap) => {
        this.fromPage = paramMap.get('page');
        this.getAllVehicle();
      },
      error => console.error(error)
    );
  }

  getAllVehicle() {
    this.vehicleService.getAllVehicle().subscribe(
      (res: Respond) => this.cars = res.result,
      error => console.error(error)
    );
  }

  getLogo(brandId: string): string | ArrayBuffer {
    return this.logos.length > 0 ? this.logos.find(logo => logo.brandId === brandId)?.img : '';
  }

  getBrandName(brandId: string): string {
    return this.brands ? this.brands.find(brand => brand.id === brandId).name : '';
  }

  createImageFromBlob(brandId: string, image: Blob) {
    const reader = new FileReader(); //you need file reader for read blob data to base64 image data.
    reader.addEventListener('load', () => this.logos.push({ brandId, img: reader.result }), false);// here is the result you got from reader
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  back() {
    if (this.fromPage === 'home') {
      this.router.navigateByUrl('/tabs/home');
    } else if (this.fromPage === 'personal') {
      this.router.navigateByUrl('/tabs/personal');
    } else {
      this.router.navigateByUrl('/booking/car');
    }
  }
}
