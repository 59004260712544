import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { STORAGE_KEY_SELECTED_ADDRESS } from 'src/app/shared/common/const';
import { CustomerAddress, Respond } from 'src/app/shared/model/model';
import { BookingService } from 'src/app/shared/service/api/booking.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class BookingAddressComponent implements OnInit {
  selectedAddress = 0;
  customerAddress: CustomerAddress[] = [
    {
      id: 'acccz',
      name: '',
      mobile: '',
      addressDistrict: '',
      addressStreet: '',
      addressUnitNo: '',
      remark: '',
      isSelected: false,
      customerId: '',
      inProfile: false
    },
    {
      id: 'zxzxv',
      name: '',
      mobile: '',
      addressDistrict: '',
      addressStreet: '',
      addressUnitNo: '',
      remark: '',
      isSelected: false,
      customerId: '',
      inProfile: false
    }
  ];
  constructor(
    public modalService: ModalService,
    private storageService: StorageService,
    private router: Router,
    private bookingService: BookingService,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    const urlData = this.router.getCurrentNavigation().extras.state;
    if (urlData) {
      this.selectedAddress = this.customerAddress.findIndex(ca => ca.id === urlData.customerAddressId);
    }
    this.loadingService.showLoading().then(
      () => {
        this.bookingService.getAllAddress().subscribe(
          (respond: Respond) => {
            if (!respond.isError && respond.result.length > 0) {
              this.customerAddress = respond.result;
              this.loadingService.hideLoading();
            } else {
              this.modalService.showError(respond);
            }
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }

  selectAddress(index: number) {
    this.storageService.set(STORAGE_KEY_SELECTED_ADDRESS, this.customerAddress[index].id)
      .then(
        () => this.router.navigateByUrl('/booking/method'),
        error => console.error(error)
      );
  }
}
