import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInput } from '@ionic/angular';
import { CANCEL_TEXT } from 'src/app/shared/common/const';
import { Respond, User } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';
import { AddressComponent } from './address/address.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  @ViewChild('otp1') otp1: IonInput;
  @ViewChild('otp2') otp2: IonInput;
  @ViewChild('otp3') otp3: IonInput;
  @ViewChild('otp4') otp4: IonInput;
  @ViewChild('otp5') otp5: IonInput;
  @ViewChild('otp6') otp6: IonInput;
  maxDate = new Date().toISOString().substring(0, 10);
  currentStep = 1;
  otp = [null, null, null, null, null, null];
  wrongMobile = false;
  wrongOTP = false;
  requiredOTP = false;
  steps = [1, 2, 3, 4];
  showPass = false;
  passNotMatch = false;
  passTooShort = false;
  passRequired = false;
  repass = '';
  nameRequired = false;
  emailRequired = false;
  provinces = [];
  wards = [];
  user: User = {
    fullName: '',
    gender: '',
    birthDate: null,
    identificationNumber: '',
    addressStreet: '',
    addressUnitNo: '',
    addressDistrict: '',
    userName: '',
    password: '',
    mobile: '',
    role: 1,
    isActive: true
  };
  constructor(
    private modalService: ModalService,
    private loadingService: LoadingService,
    private userService: UserService,
    private router: Router) { }

  ngOnInit() { }

  inputOTP(event, pre?: IonInput, current?: IonInput, next?: IonInput) {
    this.wrongOTP = false;
    if (current.value.toString().length > 0 && +current.value >= 0 && +current.value <= 9) {
      if (next) {
        next.setFocus();
      }
    } else if (event.keyCode === 8 || event.keyCode === 46) { // delete
      if (pre) {
        pre.setFocus();
      }
    } else {
      current.setFocus();
    }
  }

  checkMatchPassword() {
    if (this.repass !== this.user.password) {
      this.passNotMatch = true;
    }
  }

  sendOTP(resend = false) {
    this.loadingService.showLoading().then(
      () => {
        this.userService.createOTP(this.user.mobile.toString()).subscribe(
          (respond: Respond) => {
            if (respond.isError) {
              this.modalService.showError(respond);
            } else {
              if (!resend) {
                this.currentStep++;
              }
              setTimeout(() => {
                if (!resend) {
                  this.otp1.setFocus();
                }
              }, 0);
              this.loadingService.hideLoading();
            }
          },
          error => this.modalService.showError(error));
      },
      error => this.modalService.showError(error)
    );
  }

  closePopup() {
    this.modalService.dismiss();
  }

  openAddress() {
    this.modalService
      .openSheetModal(AddressComponent, 0.5, [0.5])
      .then(
        result => {
          if (result && result.toString() !== CANCEL_TEXT) {
            this.user.addressDistrict = `${result.province.name}/${result.district.name}/${result.ward.name}`;
          }
        },
        error => this.modalService.showError(error)
      );
  }

  step1() {
    const mobile = this.user.mobile.toString();
    if (mobile.length === 10 && mobile[0] === '0' && +mobile) {
      this.sendOTP();
    } else {
      this.wrongMobile = true;
    }
  }

  step2() {
    const otpStr = this.otp.join('');
    if (otpStr.length === 6) {
      this.loadingService.showLoading().then(
        () => {
          this.userService.verifyOTP(otpStr, this.user.mobile).subscribe(
            (respond: Respond) => {
              if (respond.result) {
                this.currentStep++;
                this.loadingService.hideLoading();
              } else {
                this.wrongOTP = true;
                this.modalService.showError('Sai mã OTP');
              }
            },
            error => this.modalService.showError(error)
          );
        },
        error => this.modalService.showError(error)
      );
    } else {
      this.requiredOTP = true;
    }
  }

  step3() {
    if (this.user.password.length === 0) {
      this.passRequired = true;
      return;
    } else if (this.user.password.length < 6) {
      this.passTooShort = true;
      return;
    } else if (this.repass !== this.user.password) {
      this.passNotMatch = true;
      return;
    } else {
      this.currentStep++;
    }
  }

  step4() {
    if (!this.user.fullName) {
      this.nameRequired = true;
      return;
    }
    if (!this.user.userName) {
      this.emailRequired = true;
      return;
    }
    if (this.currentStep >= this.steps.length && this.user.fullName) {
      this.loadingService
        .showLoading()
        .then(
          () => {
            this.userService
              .register(this.user)
              .subscribe(
                (respond: Respond) => {
                  if (respond.isError) {
                    const buttons = [{ title: 'OK', link: '' }];
                    this.modalService.notify(false, 'Lỗi!!', 'Vui lòng thử lại sau', buttons);
                  } else {
                    this.router.navigateByUrl('/login');
                  }
                  this.loadingService.hideLoading();
                },
                error => this.modalService.showError(error)
              );
          }
        );
    }
  }
}
