import { Component, OnInit } from '@angular/core';
import { Respond } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { LoadingService } from 'src/app/shared/service/common/loading.service';
import { ModalService } from 'src/app/shared/service/common/modal.service';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  oldPassword = '';
  showPass = false;
  repass = '';
  password = '';
  passNotMatch = false;
  wrongPass = false;
  passTooShort = false;
  mobile = '';
  constructor(
    private modalService: ModalService,
    private loadingService: LoadingService,
    private userService: UserService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.storageService.get('mobile').then(data => this.mobile = data);
  }

  checkMatchPassword() {
    if (this.repass !== this.password) {
      this.passNotMatch = true;
    }
  }

  changePassword() {
    if (this.repass !== this.password) {
      this.passNotMatch = true;
      return;
    }
    if (this.password.toString().length <= 6) {
      this.passTooShort = true;
      return;
    }
    this.loadingService.showLoading().then(
      data => {
        this.userService.changePassword(this.mobile, this.password, this.oldPassword).subscribe(
          (respond: Respond) => {
            if (respond.isError) {
              this.modalService.showError(respond);
            } else {
              this.loadingService.hideLoading();
              this.modalService.notify(
                true,
                'Đổi mật khẩu thành công',
                'Chào mừng bạn đến với trung tâm Đăng kiểm xe cơ giới 65-05d',
                [{ title: 'Đăng nhập', link: '/login' }],
              );
            }
          },
          error => this.modalService.showError(error)
        );
      },
      error => this.modalService.showError(error)
    );
  }
}
