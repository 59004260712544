import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from '../../common/const';
import { Booking, CustomerAddress, Respond } from '../../model/model';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http: HttpClient) { }

  deleteBooking(id: string): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/booking/delete`, { id });
  }

  getAllBooking(): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/booking/get-all`, { });
  }

  booking(booking: Booking): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/booking`, { booking });
  }

  saveAddress(customerAddress: CustomerAddress): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/customer-address/save`, { ...customerAddress });
  }

  getAllAddress(): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/customer-address/get-all`, { });
  }

  getAddress(id: string): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/customer-address/detail`, { id });
  }

  deleteAddress(id: string): Observable<Respond> {
    return this.http.post<Respond>(`${apiUrl}/customer-address/delete`, { id });
  }
}
