import { Component, OnInit } from '@angular/core';
import { Brand, Car, Logo } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { StorageService } from 'src/app/shared/service/common/storage.service';

@Component({
  selector: 'app-cancel-history',
  templateUrl: './cancel-history.component.html',
  styleUrls: ['./cancel-history.component.scss']
})
export class VehicleCancelHistoryComponent implements OnInit {
  logos: Logo[] = [];
  car: Car = {
    id: '',
    customerId: '',
    licensePlate: '30L-7878',
    brandId: '25867575-fb02-43b4-54fa-08d9dd855f57',
    lastRegistrationDate: '22/12/2222',
    expirationRegistrationDate: '22/12/2222'
  };
  brands: Brand[];
  timeline = [
    {
      title: 'Khách hàng hủy',
      date: '22/12/2021',
      note: 'Xe đủ điều kiện lưu thông',
      status: 'Đã hủy'
    },
    {
      title: 'Trung tâm hủy',
      date: '21/12/2021',
      note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      status: 'Đã hủy'
    }
  ];
  constructor(
    private userService: UserService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.storageService.get('brands').then(
      brands => {
        if (brands) {
          this.brands = brands as Brand[];
          for (const brand of this.brands) {
            this.userService.getAvatar(brand.logoUrl).subscribe(
              data => this.createImageFromBlob(brand.id, data),
              error => console.error(error)
            );
          }
        }
      },
      error => console.error(error)
    );
  }

  createImageFromBlob(brandId: string, image: Blob) {
    const reader = new FileReader(); //you need file reader for read blob data to base64 image data.
    reader.addEventListener('load', () => this.logos.push({ brandId, img: reader.result }), false);// here is the result you got from reader
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getLogo(brandId: string): string | ArrayBuffer {
    return this.logos.length > 0 ? this.logos.find(logo => logo.brandId === brandId)?.img : '';
  }

  getBrandName(brandId: string): string {
    return this.brands ? this.brands.find(brand => brand.id === brandId)?.name : '';
  }
}
