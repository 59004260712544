import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CANCEL_TEXT, OK_TEXT } from 'src/app/shared/common/const';

@Component({
  selector: 'app-confirm-negative',
  templateUrl: './confirm-negative.component.html',
  styleUrls: ['./confirm-negative.component.scss']
})
export class ConfirmNegativeComponent implements OnInit {
  message: string;
  cancelText = CANCEL_TEXT;
  okText = OK_TEXT;
  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  onClick(){}

  close(action: string) {
    this.modalController.dismiss(action);
  }
}
