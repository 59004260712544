import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { District, Province, Ward } from 'src/app/shared/model/model';
import { UserService } from 'src/app/shared/service/api/user.service';
import { CANCEL_TEXT } from 'src/app/shared/common/const';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  provinces: Province[];
  districts: District[];
  wards: Ward[];
  selectedProvince: Province;
  selectedDistrict: District;
  selectedWard: Ward;
  constructor(private modalController: ModalController, private userService: UserService) { }

  ngOnInit() {
    this.userService.getProvinces().subscribe(
      (data: Province[]) => this.provinces = data,
      err => console.error(err)
    );
  }

  selectProvince(province: Province) {
    this.selectedProvince = province;
    this.districts = province.districts;
  }

  selectDistrict(district: District) {
    this.selectedDistrict = district;
    this.userService.getWards(district.code).subscribe(
      (data: District) => this.wards = data.wards,
      err => console.error(err)
    );
  }

  selectWard(ward: Ward) {
    this.selectedWard = ward;
    this.modalController.dismiss({
      province: this.selectedProvince,
      district: this.selectedWard,
      ward: this.selectedWard,
    });
  }

  close() {
    this.modalController.dismiss(CANCEL_TEXT);
  }
}
