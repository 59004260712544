import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Staff } from 'src/app/shared/model/model';

@Component({
  selector: 'app-staff-in-charge',
  templateUrl: './staff-in-charge.component.html',
  styleUrls: ['./staff-in-charge.component.scss']
})
export class StaffInChargeComponent implements OnInit {
  processId = '';
  staff: Staff = {
    id: '',
    staffNumber: '',
    userId: ''
  };
  constructor(private activateRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activateRoute.paramMap.subscribe((paramMap) => this.processId = paramMap.get('processId'));
  }

}
