import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  buttons = [
    { title: 'Tất cả', active: true },
    { title: 'Đăng kiểm', active: false },
    { title: 'Cập nhật', active: false }
  ];
  notifications = [
    {
      title: 'Đăng kiểm đạt',
      message: 'Xe Toyota Vios biển số 88-5866 đã hoàn thành đăng kiểm, xe của bạn đã đạt yêu cầu',
      time: '4 giờ trước'
    },
    {
      title: 'Đăng kiểm đạt',
      message: 'Xe Toyota Vios biển số 88-5866 đã hoàn thành đăng kiểm, xe của bạn đã đạt yêu cầu',
      time: '4 giờ trước'
    },
    {
      title: 'Đăng kiểm đạt',
      message: 'Xe Toyota Vios biển số 88-5866 đã hoàn thành đăng kiểm, xe của bạn đã đạt yêu cầu',
      time: '4 giờ trước'
    },
    {
      title: 'Đăng kiểm đạt',
      message: 'Xe Toyota Vios biển số 88-5866 đã hoàn thành đăng kiểm, xe của bạn đã đạt yêu cầu',
      time: '4 giờ trước'
    },
    {
      title: 'Đăng kiểm đạt',
      message: 'Xe Toyota Vios biển số 88-5866 đã hoàn thành đăng kiểm, xe của bạn đã đạt yêu cầu',
      time: '4 giờ trước'
    }
  ];
  constructor() { }

  ngOnInit() {
  }

  clickTab(index: number) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.buttons.length; i++) {
      if (index === i) {
        this.buttons[i].active = true;
      } else {
        this.buttons[i].active = false;
      }
    }
  }
}
