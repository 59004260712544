import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ComponentProps, ComponentRef } from '@ionic/core';
import { ConfirmNegativeComponent } from 'src/app/page/common/popup/confirm-negative/confirm-negative.component';
import { NotifyComponent } from 'src/app/page/common/popup/notify/notify.component';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalController: ModalController, private loadingService: LoadingService) { }

  async confirmNegative(message: string) {
    const modal = await this.modalController.create({
      component: ConfirmNegativeComponent,
      componentProps: { message }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  async notify(isSuccess: boolean, headerText: string, message: string, buttons: { link: string; title: string }[]) {
    const modal = await this.modalController.create({
      component: NotifyComponent,
      componentProps: { isSuccess, headerText, message, buttons }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  showError(error?: any) {
    let message = 'Vui lòng thử lại sau';
    if (typeof error === 'string') {
      message = error;
    } else if (error?.error?.responseException?.exceptionMessage) {
      message = error.error.responseException.exceptionMessage.title;
    } else if (error?.responseException?.exceptionMessage) {
      message = error.responseException.exceptionMessage;
    } else {
      console.error(error);
    }
    this.loadingService.hideLoading();
    this.notify(false, 'Lỗi!!!', message, [{ link: '', title: 'OK' }]);
  }

  async openSheetModal(component: ComponentRef, initialBreakpoint: number, breakpoints: number[], componentProps?: ComponentProps) {
    const modal = await this.modalController.create({ component, initialBreakpoint, breakpoints, cssClass: 'sheet-modal', componentProps });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
